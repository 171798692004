
:root {
  /* --red: #ff0000;
  --orange: #ffa700;
  --yellow: #fae100;
  --lightGreen: #a3ff00;
  --green: #2cba00;
  --blue: #0077c0;
  --purple: #9966ff; */

  /* --red: #ff4c4c;
  --orange: #ffc14c;
  --yellow: #fff74c;
  --lightGreen: #a6e293;
  --green: #6bcf4c;
  --blue: #3d5af3; #4c6bcf; 
  --purple: #b04ccf; */

  /* --red: #f94449;
  --redOrange: "#fc843e";
  --orange: #ffc333;
  --orangeYellow: "#fddf33";
  --yellow: #fafa33;
  --yellowGreen: "#b3e540";
  --green: #6bcf4c;
  --lightGreen: #5af33d;
  --blue: #3d5af3;
  --purple: #b04ccf; */

  --red: #f9554e;
  --redOrange: #fd9d65;
  --orange: #ffcf5c;
  --orangeYellow: #fde55c;
  --yellow: #fbfb5c;
  --yellowGreen: #c2ea66;
  --green: #89d970;
  --lightGreen: #7bf564;
  --blue: #3d5af3;
  --purple: #b04ccf;
  --darkGrey: #545454;

  --table-border: #545454;
};

.test {
  color: var(--orange);
}

.fg-red { color: var(--red); }
.fg-orange {
  color: var(--orange);
}
.fg-yellow {
  color: var(--yellow);
}
.fg-lightGreen {
  color: var(--lightGreen);
}
.fg-green {
  color: var(--green);
}
.fg-blue {
  color: var(--blue);
}
.fg-purple {
  color: var(--purple);
}
.fg-white { color: white !important; }

.bg-red { background-color: var(--red) !important; }
.bg-redOrange { background-color: var(--redOrange) !important; }
.bg-orange { background-color: var(--orange) !important; }
.bg-orangeYellow { background-color: var(--orangeYellow) !important; }
.bg-yellow { background-color: var(--yellow) !important; }
.bg-yellowGreen { background-color: var(--yellowGreen) !important; }
.bg-green { background-color: var(--green) !important; }
.bg-lightGreen { background-color: var(--lightGreen) !important; }
.bg-blue { background-color: var(--blue) !important; }
.bg-purple { background-color: var(--purple) !important; }
.bg-darkGrey { background-color: var(--darkGrey) !important; }

.ag-foreground-color {
  color: var(--ag-data-color);
}

.app-content {
  height: 100%;
}

.flex-container {
  display: flex;
  width: 100vw;
  flex-direction: row wrap;
  justify-content: space-around;
  align-items: stretch;
  box-sizing: border-box;
  gap: 20px;
}

.flex-item {
  flex-basis: 45%;
  width: 45vw;
  box-sizing: border-box;
}
.flex-item-full {
  flex-basis: 80%;
  max-height: 80vh;
  align-self: center;
  box-sizing: border-box;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-grow-1 { flex-grow: 1; }
.flex-grow-2 { flex-grow: 2; }
.flex-grow-3 { flex-grow: 3; }
.flex-grow-4 { flex-grow: 4; }
.flex-grow-5 { flex-grow: 4; }
.flex-grow-6 { flex-grow: 4; }
.flex-grow-7 { flex-grow: 4; }
.flex-grow-8 { flex-grow: 4; }
.flex-grow-9 { flex-grow: 4; }
.flex-grow-10 { flex-grow: 4; }
.flex-grow-11 { flex-grow: 4; }
.flex-grow-12 { flex-grow: 4; }

.flex-box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.flex-content-auto {
  flex: 0 1 auto;
}

.flex-content-grow {
  flex: 1 1 auto;
}

.tab-content {
  height: 100%;
}

.tab-content > .active {
  height: 100%;
}

.chart-container {
  position: relative;
  margin: auto;
  height: 92vh;
  width: 100%;
  /* padding: 10px; */
}
/* .chart-container > canvas {
  width: 700px !important;
  height: 700px !important;
} */
/* .chart-container > svg {
  width: 800px;
  height: 600px;
} */
/* canvas {
  margin: 0 auto;
} */

.separate-table {
  border-collapse: separate;
}

.separate-table tr {
  border-top-width: 1px !important;
  border-bottom-width: 1px !important;
}

.separate-table th, 
.separate-table td {
  border-spacing: 0px;
  border-left-width: 1px;
  border-bottom-width: 1px;
}

.table-text-center-middle th,
.table-text-center-middle td,
.row-text-center-middle th,
.row-text-center-middle td,
.text-center-middle {
  text-align: center !important;
  vertical-align: middle !important;
}

.circular-bar-tooltip {
  position: absolute;
  text-align: center;							
  padding: .5rem;				
  background: #FFFFFF;
  color: #313639;	
  border: 1px solid #313639;		
  border-radius: 8px;			
  pointer-events: none;
  font-size: 1.3rem;	
}

.tooltip {
  pointer-events: none;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-content: center;
}

.align-self-center {
  align-self: center;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr) auto;
  /* grid-template-rows: 2fr repeat(4, 1fr) minmax(1fr, auto); */
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.span1 { grid-area: auto / span 1; }
.div2 { grid-area: 1 / 2 / 3 / 3; }
.div3 { grid-area: 2 / 1 / 3 / 2; }
.div4 { grid-area: 3 / 1 / 4 / 2; }
.div5 { grid-area: 4 / 1 / 5 / 3; }

.tile-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.tile {
  flex: 1;
  padding: 8px;
  margin: 10px;
  text-align: center !important;
  vertical-align: middle !important;
  width: 120px !important;
  height: 160px !important;
  color: #3c3c3c !important;
}

.tile h5 {
  color: white;
  font-size: 40px;
  margin: 0;
  /* background-color: #313639; */
  text-shadow: 0 0 28px black;
  /* text-shadow: 1px 0 grey, 0 2px rgba(0,0,0,.2), -2px 0 rgba(0,0,0,.2), 0 -2px rgba(0,0,0,.2); */
  /* text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
             0px 8px 13px rgba(0,0,0,0.1),
             0px 18px 23px rgba(0,0,0,0.1); */
  /* -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(0,0,0,.75); */
}

.tile span {
  font-size: 60px;
}

.circle-container {
  width: 100px;
  height: 100px;
  align-items: center;
}

.circle {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center !important;
}

.circle > div {
  text-align: center;
  font-weight: 600;
  font-size: 35px;
}

.clickable {
  cursor: pointer;
}

.comment-button {
  cursor: pointer;
  position: fixed;
  position: fixed;
  bottom: 10px;
  right: 10px; 
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rotate-90 {
  /* display: inline; */
  /* letter-spacing: 10px; */
  /* height: 60px; */
  /* padding: 15px; */
  position: relative;
}

.rotate-90 div {
  transform-origin: bottom right;
  transform: 
    translate(25px, 30px)
    rotate(-90deg);
}

.table-xs {
  font-size: 12px;
}

.table-xs th, .table-xs td {
  padding: 0.1rem !important;
  min-width: 30px;
}

.tr-top {
  border-top-color: var(--table-border);
  border-left-color: var(--table-border);
  border-right-color: var(--table-border);
  border-top-width: 2px !important;
  border-left-width: 2px !important;
  border-right-width: 2px !important;
}
.tr-top th, .tr-top td {
  border-color: rgb(222, 226, 230);
  background-color: #d1edfc !important; 
}
.tr-mid {
  border-left-color: var(--table-border);
  border-right-color: var(--table-border);
  border-left-width: 2px !important;
  border-right-width: 2px !important;
}
.tr-mid th, .tr-mid td {
  border-color: rgb(222, 226, 230);
}
.tr-bottom {
  border-bottom-color: var(--table-border);
  border-left-color: var(--table-border);
  border-right-color: var(--table-border);
  border-bottom-width: 2px !important;
  border-left-width: 2px !important;
  border-right-width: 2px !important;
}
.tr-bottom th, .tr-bottom td {
  border-color: rgb(222, 226, 230);
}

.modal-70w {
  min-width: 70% !important;
}

.number-cell {
  width: 60px;
}



@media(max-width: 600px) {
  
}

span.sv-string-viewer {
  text-wrap: wrap;
  color: #545454;
}

span.sd-rating__min-text, span.sd-rating__max-text {
  width: 33%;
}

span.sd-rating__max-text {
  align-content: right;
}

img.sd-logo__image {
  height: initial;
}

.sd-body__navigation {
  justify-content: end;
}

@media(max-width: 1199px) {
  span.sd-rating__max-text > span.sv-string-viewer {
    margin-left: 10px;
    text-align: center;
  }
  .sd-rating {
    justify-content: center;
    text-align: center;
  }
  .sd-rating fieldset {
    display: block;
  }
  .sd-rating fieldset > label {
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  span.sd-rating__min-text, span.sd-rating__max-text {
    width: 100%;
  }
}


/* Cube */
#experiment {
  -webkit-perspective: 800px;
  -webkit-perspective-origin: 50% 200px;
  perspective: 800px;
  perspective-origin: 50% 200px;
}

#cube {
  position: relative;
  margin: 0 auto;
  height: 400px;
  width: 400px;
  -webkit-transition: -webkit-transform 2s linear;
  -webkit-transform-style: preserve-3d;
  transition: -webkit-transform 2s linear;
  transform-style: preserve-3d;
}

#cube > div:first-child  {
  -webkit-transform: rotateX(90deg) translateZ(200px);
  -moz-transform: rotateX(90deg) translateZ(200px);
  transform: rotateX(90deg) translateZ(200px);
  }
  
  #cube > div:nth-child(2) {
  -webkit-transform: translateZ(200px);
  -moz-transform: translateZ(200px);
  transform: translateZ(200px);
  }
  
  #cube > div:nth-child(3) {
  -webkit-transform: rotateY(90deg) translateZ(200px);
  -moz-transform: rotateY(90deg) translateZ(200px);
  transform: rotateY(90deg) translateZ(200px);
  text-align: center;
  }
  
  #cube > div:nth-child(4) {
  -webkit-transform: rotateY(180deg) translateZ(200px);
  -moz-transform: rotateY(180deg) translateZ(200px);
  transform: rotateY(180deg) translateZ(200px);
  }
  
  #cube > div:nth-child(5) {
  -webkit-transform: rotateY(-90deg) translateZ(200px);
  -moz-transform: rotateY(-90deg) translateZ(200px);
  transform: rotateY(-90deg) translateZ(200px);
  }
  
  #cube > div:nth-child(6) {
  -webkit-transform: rotateX(-90deg) rotate(180deg) translateZ(200px);
  -moz-transform: rotateX(-90deg) rotate(180deg) translateZ(200px);
  transform: rotateX(-90deg) rotate(180deg) translateZ(200px);
  }


  .ag-theme-quartz {
    /* --ag-foreground-color: rgb(126, 46, 132);
    --ag-background-color: rgb(249, 245, 227);
    --ag-header-foreground-color: rgb(204, 245, 172);
    --ag-header-background-color: rgb(209, 64, 129);
    --ag-odd-row-background-color: rgb(0, 0, 0, 0.03);
    --ag-header-column-resize-handle-color: rgb(126, 46, 132); */

    --ag-font-size: 17px;
    /* --ag-font-family: monospace; */
  }

  .ag-row .ag-cell {
    display: flex;
    /* justify-content: center; align horizontal */
    align-items: center;
  }